@charset "UTF-8";
@font-face {
  font-family: 'saf';
  src: url('../font/saf.eot?42305549');
  src: url('../font/saf.eot?42305549#iefix') format('embedded-opentype'),
       url('../font/saf.woff2?42305549') format('woff2'),
       url('../font/saf.woff?42305549') format('woff'),
       url('../font/saf.ttf?42305549') format('truetype'),
       url('../font/saf.svg?42305549#saf') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'saf';
    src: url('../font/saf.svg?42305549#saf') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "saf";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-certificate-outline:before { content: '\e0f2'; } /* '' */
.icon-award-empty:before { content: '\e764'; } /* '' */
.icon-logout:before { content: '\e800'; } /* '' */
.icon-users-outline:before { content: '\e801'; } /* '' */
.icon-home:before { content: '\e802'; } /* '' */
.icon-award:before { content: '\e802'; } /* '' */
.icon-home-outline:before { content: '\e803'; } /* '' */
.icon-gauge:before { content: '\e804'; } /* '' */
.icon-trash:before { content: '\e805'; } /* '' */
.icon-user-outline:before { content: '\e806'; } /* '' */
.icon-link:before { content: '\e807'; } /* '' */
.icon-link-1:before { content: '\e808'; } /* '' */
.icon-paper-plane:before { content: '\e809'; } /* '' */
.icon-user-add-outline:before { content: '\e80a'; } /* '' */
.icon-user-delete-outline:before { content: '\e80b'; } /* '' */
.icon-doc-text-1:before { content: '\e80c'; } /* '' */
.icon-clipboard:before { content: '\e80d'; } /* '' */
.icon-chart-bar-outline:before { content: '\e80e'; } /* '' */
.icon-chart-bar:before { content: '\e80f'; } /* '' */
.icon-chart-pie-outline:before { content: '\e810'; } /* '' */
.icon-chart-pie:before { content: '\e811'; } /* '' */
.icon-credit-card:before { content: '\e812'; } /* '' */
.icon-globe-alt:before { content: '\e813'; } /* '' */
.icon-globe-alt-outline:before { content: '\e814'; } /* '' */
.icon-globe:before { content: '\e815'; } /* '' */
.icon-globe-outline:before { content: '\e816'; } /* '' */
.icon-graduation-cap:before { content: '\e817'; } /* '' */
.icon-ticket:before { content: '\e818'; } /* '' */
.icon-info:before { content: '\e819'; } /* '' */
.icon-info-outline:before { content: '\e81a'; } /* '' */
.icon-attach:before { content: '\e81b'; } /* '' */
.icon-feather:before { content: '\e81c'; } /* '' */
.icon-pencil:before { content: '\e81d'; } /* '' */
.icon-cog-outline:before { content: '\e81e'; } /* '' */
.icon-dot-3:before { content: '\e81f'; } /* '' */
.icon-block:before { content: '\e820'; } /* '' */
.icon-edit:before { content: '\e821'; } /* '' */
.icon-eye:before { content: '\e822'; } /* '' */
.icon-eye-1:before { content: '\e823'; } /* '' */
.icon-certificate:before { content: '\e824'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-info-1:before { content: '\f129'; } /* '' */
.icon-pencil-squared:before { content: '\f14b'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-chart-pie-1:before { content: '\f200'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-wpforms:before { content: '\f298'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-award-1:before { content: '🏉'; } /* '\1f3c9' */
