#upload-button {
    width: 120px;
    position: relative;
    cursor: pointer;
    height: 32px;
    color: rgb(0, 0, 0);
    border-radius: 3px;
    font-size: 12px;
    transition: all 0.3s ease-out 0s;
    text-align: center;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
}

#file-to-upload {
    display: none;
}

#pdf-main-container {
    width: 400px;
    margin: 20px auto;
}

#pdf-meta {
    overflow: hidden;
}

.holder-btn {
    position: absolute;
    right: 8%;
    top: -12%;
}
#page-count-container {
    float: right;
}

#pdf-current-page {
    display: inline;
}

#pdf-total-pages {
    display: inline;
}

#image-convas-row {
    display: flex;
    margin: 20px 0;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
}
.options {
    position: absolute;
    top: 4%;
    right: 4%;
    z-index: 1;
}

.options-btn {
    background: rgba(0, 0, 0, 0.349);
    color: black;
    padding-bottom: 5px;
    border-radius: 50%;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border: 0.125rem solid transparent;
    width: 40px;
    font-weight: bolder;
    text-align: center;
    box-shadow: rgb(47 5 187 / 20%) 1px 3px 6px;
    cursor: pointer;
}

#pdf-canvas {
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    max-width: 200px;
}

#download-image {
    width: 150px;
    display: block;
    margin: 20px auto 0 auto;
    font-size: 13px;
    text-align: center;
}

#image-generated {
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 50px #ccc;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    filter: none;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
}

.uploader {
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 50px #ccc;
    padding: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 430px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: -1rem;
    cursor: pointer;
}

.uploader-text {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    margin-left: 1rem;
}
#pdf-contents {
    margin-top: -2rem;
}

.arrow {
    cursor: pointer;
    position: relative;
    display: block;
    margin: 100px 0 0 100px;
    width: 30px;
    height: 30px;
    border: solid 6px #000;
    border-radius: 100%;
    z-index: 1;
    transition: all 0.2s linear;
}
.arrow:before,
.arrow:after {
    content: '';
    position: absolute;
    width: 35%;
    height: 10%;
    top: 41%;
    left: 55%;
    background: #000;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.2s linear;
}
.arrow:after {
    z-index: 3;
    top: 59%;
    left: 55%;
    transform: translate(-50%, -50%) rotate(-45deg);
}
.arrow:hover {
    border: solid 8px #777;
}
.arrow:hover:after,
.arrow:hover:before {
    background: #777;
}
.arrow:active {
    border: solid 8px #111;
}
.arrow:active:after,
.arrow:active:before {
    background: #111;
}
